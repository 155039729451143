<template>
  <div class="chat-window">
    <div>
      <el-select v-model="selectedModel" placeholder="请选择模型" @change="handleSelectionChange">
        <el-option
            v-for="option in options"
            :key="option.value"
            :label="option.label"
            :value="option.value"
        >
          <template #default>
            <i v-if="option.locked" class="el-icon-lock" style="margin-right: 5px;"></i>
            <el-tooltip class="item" effect="dark" :content="option.description" placement="right">
              <span>{{ option.label }}</span>
            </el-tooltip>
          </template>
        </el-option>
      </el-select>
    </div>
    <div class="top">

      <el-row style="height: 70px;">
        <!--        信息和描述-->
        <el-col :span="personInfoSpan[1]">
          <div class="info-detail">
            <div class="name">{{ friendInfo.name }}</div>
            <!--            <div class="detail">{{ friendInfo.detail }}</div>-->
          </div>
        </el-col>
        <el-col :span="personInfoSpan[2]">
        </el-col>
      </el-row>
    </div>
    <div v-show="!acqStatus">
      <div class="line"></div>
    </div>


    <el-dialog
        :visible.sync="errorDialogVisible"
        title="请使用支付宝扫码支付！"
        width="400px"
        :before-close="handleErrorDialogClose"
        :modal-append-to-body="false">
      <div style="text-align: center; margin-bottom: 6px; font-size: larger; font-weight: bold;">支付金额:
        {{ totalAmount }}
      </div> <!-- 显示支付金额 -->
      <div style="height: 200px" class="text-container"> <!-- 设置弹窗的高度 -->
        <!--支付宝扫码支付-->
        <el-col :span="4" class="button-col">
          <el-row class="button-container">
            <canvas ref="qrcodeCanvas"></canvas>
          </el-row>
        </el-col>
      </div>
      <div class="button-container">
      <span slot="footer" class="dialog-footer">
         <el-button @click="handleErrorDialogCancel">取消支付</el-button>
        <el-button type="primary" @click="handleErrorDialogConfirm">支付成功</el-button>
       </span>
      </div>
    </el-dialog>

    <div class="botoom">
      <div class="chat-content" id="chat-content" ref="chatContent" @scroll="onScroll">
        <div class="chat-wrapper" v-for="item in chatList" :key="item.id">
          <div class="chat-friend" v-if="item.uid !== 'user'">
            <div class="chat-text" v-if="item.chatType === 0">
              <el-row :gutter="20">
                <el-col :span="2">
                  <svg t="1679666016648" @click="$copy(item.msg, '已复制')" class="icon" viewBox="0 0 1024 1024"
                       version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6241" width="22" height="22">
                    <path
                        d="M661.333333 234.666667A64 64 0 0 1 725.333333 298.666667v597.333333a64 64 0 0 1-64 64h-469.333333A64 64 0 0 1 128 896V298.666667a64 64 0 0 1 64-64z m-21.333333 85.333333H213.333333v554.666667h426.666667v-554.666667z m191.829333-256a64 64 0 0 1 63.744 57.856l0.256 6.144v575.701333a42.666667 42.666667 0 0 1-85.034666 4.992l-0.298667-4.992V149.333333H384a42.666667 42.666667 0 0 1-42.368-37.674666L341.333333 106.666667a42.666667 42.666667 0 0 1 37.674667-42.368L384 64h447.829333z"
                        fill="#909399" p-id="6242"></path>
                  </svg>
                </el-col>
                <el-col :span="21">
                </el-col>
              </el-row>
              <markdown-it-vue-light :content="item.msg"/>
            </div>
            <div class="info-time">
              <img :src="item.headImg" alt=""/>
              <span>{{ item.name }}</span>
              <span>{{ item.time }}</span>
            </div>
          </div>
          <!--发送者消息框-->
          <div class="chat-me" v-else>
            <div class="chat-text" v-if="item.chatType === 0">
              <!-- 文字展示 -->
              <span style="font-size:16px">{{ item.msg }}</span>
            </div>
            <!-- 图片或文件展示 -->
            <div v-if="item.uploadedFileUrl" class="chat-file">
              <img v-if="isImage(item.uploadedFileUrl)" :src="item.uploadedFileUrl" alt="上传的图片" style="border-radius: 8px; margin-top: 10px;" />
              <span v-else style="display: block; margin-top: 10px;">{{ getFileName(item.uploadedFileUrl) }}</span>
            </div>
            <div class="info-time">
              <span>{{ item.name }}</span>
              <span>{{ item.time }}</span>
              <img :src="item.headImg" alt=""/>
            </div>
          </div>
        </div>
      </div>

      <div class="chatInputs">
        <!--头像-->
        <div class="ChatPros" style="left: 20px">
          <el-col :span="personInfoSpan[0]">
            <div class="head-pic" @click="showPayDialog">
              <HeadPortrait :imgUrl="friendInfo.headImg"></HeadPortrait>
            </div>
          </el-col>
        </div>

        <div class="file-upload-container" style="position: relative;">
          <!-- 输入框 -->
          <textarea
              id="textareaMsg"
              :placeholder="$t('placeholder.question')"
              class="inputs"
              v-autoheight
              v-model="inputMsg"
              style="z-index: 1; min-height: 50px; max-height: 400px; max-width: 100%; min-width: 45%; color: black; padding-left: 70px; padding-right: 40px; position: relative;"
              maxlength="2048"
              rows="3"
              ref="textarea"
              @keyup.enter="sendText"
          ></textarea>

          <!-- 上传的文件展示区域 -->
          <div
              v-if="uploadedFileUrl"
              class="uploaded-file-container"
              style="position: absolute; top: 50%; left: 40px; transform: translateY(-50%); z-index: 2;"
          >
            <!-- 清除按钮 -->
            <button
                class="clear-button"
                @click="clearUploadedFile"
                style="position: absolute; top: -10px; right: -10px; background: red; color: white; border: none; border-radius: 50%; width: 20px; height: 20px; display: flex; justify-content: center; align-items: center; cursor: pointer;"
            >
              ×
            </button>

            <!-- 如果是图片，显示缩略图 -->
            <img
                v-if="isImageFile"
                :src="uploadedFileUrl"
                alt="上传的文件"
                style="width: 40px; height: 40px; object-fit: cover; border-radius: 4px;"
            />
            <!-- 如果不是图片，显示文件名 -->
            <span v-else style="font-size: 12px; color: #666;">
            {{ uploadedFileName }}
            </span>
          </div>

          <!-- 上传文件按钮 -->
          <div
              class="upload-container"
              style="position: absolute; top: 50%; left: 60px; transform: translateY(-50%); z-index: 2;"
          >
            <button
                class="upload-button"
                @click="triggerFileUpload"
                style="width: 30px; height: 30px; border: none; background-color: transparent; cursor: pointer; display: flex; justify-content: center; align-items: center;"
            >
              <img src="@/assets/img/icons/upload-icon.png" alt="上传" style="width: 20px; height: 20px;" />
            </button>
            <input
                type="file"
                ref="fileInput"
                @change="handleFileChange"
                style="display: none;"
            />
          </div>

          <!-- 发送按钮 -->
          <div
              class="send-container"
              style="position: absolute; top: 49%; right: 37px; transform: translateY(-50%); z-index: 2;"
          >
            <button
                class="send-button"
                @click="sendText"
                style="width: 30px; height: 30px; border: none; background-color: transparent; cursor: pointer; display: flex; justify-content: center; align-items: center;"
            >
              <img src="@/assets/img/icons/sendx.png" alt="发送" style="width: 100px; height: 50px;" />
            </button>
          </div>
        </div>


        <div class="between-margin">
          <el-popover
              v-model="popoverVisible"
              placement="top-start"
              width="200"
              trigger="click"
          >
          </el-popover>


          <el-dialog
              title="ChatPro"
              :visible.sync="dialogVisible"
              width="38%"
              :modal="false"
          >
          <div class="dialog-content">
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;通过加入 <strong>ChatPro</strong>, <span class="highlight-price">仅需10元/月</span>，你将获得访问最先进的人工智能模型的机会，这些模型由 <strong>OpenAI</strong> 开发，包括但不限于 GPT-4o 系列,这不仅意味着更强的推理能力和更高的准确性，还意味着你可以体验到前所未有的自然语言理解和生成水平。
            </p>
            <h4>解锁 GPT-4：</h4>
            <p>
              享受增强的逻辑推理能力与精准度，为用户提供更为流畅和深入的对话交互。
            </p>
            <h4>探索 GPT-4o：</h4>
            <p>
              此版本特别增强了在线搜索功能，允许 AI 实时检索最新信息，确保你得到的答案始终是最新的。
            </p>
            <h4>体验旗舰模型 GPT-4o-all：</h4>
            <p>
              结合了实时推理、音频及视觉处理的强大特性，支持联网搜索，提供超越文本的全方位互动。无论你想讨论最新的科学研究，还是分析一段视频的内容，这个模型都能满足你的需求。
            </p>
            <p class="highlight">
              <span class="gradient-text">立即加入 ChatPro，开启智能对话的新篇章！</span>
            </p>
          </div>
          <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center;">
             <el-button @click="handleDialogClose">取消</el-button>
             <el-button type="primary" @click="handleDialogConfirm">加入 ChatPro</el-button>
          </span>
          </el-dialog>
        </div>
        <div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {animation, getNowTime, FormatDate} from "@/util/util";
import HeadPortrait from "@/components/HeadPortrait";
import MarkdownItVueLight from 'markdown-it-vue/dist/markdown-it-vue-light.umd.min.js'
import 'markdown-it-vue/dist/markdown-it-vue-light.css'
import {AI_HEAD_IMG_URL, USER_HEAD_IMG_URL, USER_NAME} from '@/store/mutation-types'
import axios from '@/util/request';
import QRCode from "qrcode";

export default {
  directives: {
    //用于自适应文本框的高度
    autoheight: {
      inserted: function (el) {
        var Msg = document.getElementById("textareaMsg").value;
        if (Msg === "") {
          el.style.height = "26px"
        } else {
          el.style.height = el.scrollHeight + 'px'
        }
      },
      update: function (el) {
        const Msg = document.getElementById("textareaMsg").value;
        if (Msg === "") {
          el.style.height = "26px"
        } else {
          el.style.height = el.scrollHeight + 'px'
        }
      }
    }
  },
  components: {
    HeadPortrait,
    MarkdownItVueLight
  },
  props: {
    storeStatu: Number,
    settingInfo: Object,
    friendInfo: Object,
    default() {
      return {};
    },
  },
  watch: {},
  data() {
    return {
      userInfoToken: '',
      textareaStyle: {},
      inputMsgs: '',
      imageSrc: '',
      userPhone: '',
      billingUsage: '0',
      dialogVisible: false, // 控制模态框的可见性
      isAutoScroll: true,
      //是否在接收消息中，如果是则true待发送状态，如果是false则是等待消息转圈状态
      acqStatus: true,
      chatList: [],
      inputMsg: "",
      // friendInfo: {},
      // 是否隐藏对话框上方介绍（空间局促时隐藏）
      personInfoSpan: [1, 17, 6],
      qrCode: '',
      errorDialogVisible: false, // //支付弹框控制弹窗显示/隐藏
      errorMessage: '', // 错误消息
      totalAmount: '', //支付总金额
      payNumber: '',//订单id
      timer: null, //查询订单状态定时器
      userId: '',
      userToken: '',
      content: '',
      session: '',
      selectedModel: 'gpt-3.5-turbo', // 设置默认选择为 GPT 3.5
      userSelectedModel: null, // 用户选择的模型
      popoverVisible: false,
      uploadedFileUrl: null, // 上传成功后文件的 URL
      uploadedFileName: "", // 上传成功后文件名
      options: [
        {
          label: 'gpt-3.5',
          value: 'gpt-3.5-turbo',
          locked: false,
          description: 'GPT-3.5 Turbo是一组改进了GPT-3.5的模型，能够理解并生成自然语言或代码，并且已经针对使用Chat Completions API进行了优化，同时也可以很好地用于非聊天任务'
        },
        {label: 'gpt-4', value: 'gpt-4', locked: true, description: 'GPT-4模型，具有更强的推理能力和更高的准确性。'},
        {
          label: 'claude-3-5',
          value: 'claude-3-5-sonnet-20240620',
          locked: true,
          description: 'Claude 3.5模型，适用于对话生成和语言理解。'
        },
        {
          label: 'gpt-4o',
          value: 'gpt-4o',
          locked: true,
          description: 'GPT-4的优化版，适合高效运算任务，无法联网，知识库截止2022年。'
        },
        {
          label: 'gpt-4-all',
          value: 'gpt-4-all',
          locked: true,
          description: 'GPT-4版本，GPT-4o，支持联网搜索，知识库截止2023年10月'
        },
        {
          label: 'gpt-4o-all',
          value: 'gpt-4o-all',
          locked: true,
          description: '是openAI的旗舰型号，实时推理音频、视觉和文本，支持联网搜索。'
        },
      ]

    };
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize();
    this.getUserInfo();
  },

  async mounted() {
    await this.getUserInfo();
    this.checkIsVip();
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },

  computed: {
    // 是否是图片文件
    isImageFile() {
      if (!this.uploadedFileUrl) return false;
      return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(this.uploadedFileUrl);
    },
  },
  methods: {
    /**
     * 获取用户信息
     */
    async getUserInfo() {
      this.userInfoToken = localStorage.getItem('userInfoToken');
      if (!this.userInfoToken) {
        return;
      }
      try {
        const response = await axios({
          method: 'GET',
          url: process.env.VUE_APP_SERVER + '/api/user/getUserInfo',
          headers: {
            'token': this.userInfoToken
          }
        });
        if (response.data.code === '0') {
          let result = response.data;
          this.userId = result.data.userId;
          this.userPhone = result.data.phone;
        } else if (response.data.code === '555') {
          this.$message.error('token过期，请重新登录');
        } else {
          this.$message.error('用户信息获取失败');
        }
      } catch (error) {
        console.error("获取用户信息失败：", error);
        this.$message.error('用户信息获取失败');
      }
    },

    handlePaste(event) {
      const clipboardData = event.clipboardData;
      const items = clipboardData.items;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.type.indexOf('image') !== -1) {
          const file = item.getAsFile();
          const reader = new FileReader();

          reader.onload = (event) => {
            this.imageSrc = event.target.result;
            this.adjustTextareaHeight(); // Adjust textarea height after loading image
          };
          reader.readAsDataURL(file);

          event.preventDefault();
          break;
        }
      }
    },

    removeImage() {
      this.imageSrc = '';
      this.adjustTextareaHeight(); // Adjust textarea height after removing image
    },
    adjustTextareaHeight() {
      this.$nextTick(() => {
        const textarea = this.$refs.textarea;
        if (textarea) {
          textarea.style.height = 'auto'; // Reset height
          if (this.imageSrc) {
            textarea.style.height = `${textarea.scrollHeight + 70}px`;
          } else {
            textarea.style.height = `${textarea.scrollHeight}px`;
          }
        }
      });
    },
    updated() {
      this.adjustTextareaHeight(); // Ensure textarea height is adjusted after update
    },

    /**
     * 选择模型
     */
    async handleSelectionChange(value) {
      console.log('选择的模型:', this.selectedModel);
      // 获取所有需要VIP权限的模型
      const specialModels = this.options.filter(option => option.value !== 'gpt-3.5-turbo' && option.locked).map(option => option.value);
      // 检查是否是VIP用户
      const isVipSuccess = await this.checkIsVip();

      // 如果选择的模型属于需要VIP的模型，进行相应的处理
      if (specialModels.includes(value)) {
        if (isVipSuccess) {
          this.dialogVisible = false;   // 如果是VIP用户，关闭模态框
          this.userSelectedModel = value;
        } else {
          this.dialogVisible = true;    // 如果不是VIP用户，打开模态框
          this.userSelectedModel = value;
        }
      } else {
        this.popoverVisible = false;    // 关闭Popover，不需要弹窗处理
      }

      // 无论是否VIP，Popover都会被关闭
      this.popoverVisible = false;
    },

    handleDialogConfirm() {
      this.errorDialogVisible = true; // 显示支付弹框
      this.dialogVisible = false;
      let userId = this.userId;
      if (userId === null) { // 检查userId是否为null
        this.$notify({
          title: '参数不合法！',
          duration: 2000,
          type: 'error',
          position: 'top-right'
        });
        return;
      }
      this.userId = userId; // 设置userId
      this.Pay(userId); // 调用支付方法
      // 定义定时器 每隔5秒查询支付状态
      this.timer = setInterval(async () => {
        const isSuccessful = await this.queryPaymentStatus();
        if (isSuccessful) {
          this.errorDialogVisible = false;
          this.$notify({
            title: '恭喜您支付成功！',
            duration: 2000,
            type: 'success',
            position: 'top-right'
          });
          // 支付成功，设置为用户选择的模型
          console.log("支付成功选择的模型是" + this.userSelectedModel);
          this.selectedModel = this.userSelectedModel;
          // 遍历 options 数组，将所有模型的 locked 属性设置为 false
          this.options.forEach(option => {
            option.locked = false;
          });
          this.stopTimer(); // 停止定时器
        } else {
          // 设置为默认模型
          this.selectedModel = 'gpt-3.5-turbo';
          console.log('支付失败');
        }
      }, 5000);
    },

    // 取消
    handleDialogClose() {
      this.dialogVisible = false;
      this.selectedModel = 'gpt-3.5-turbo';
    },

    handleKeyDown(event) {
      if (event.keyCode === 13 && (!event.shiftKey)) {  // 按下回车键，没按shift
        this.sendText()
      }
    },

    //监听窗口的变化
    handleResize() {
      if (window.innerWidth <= 700) {
        this.$nextTick(() => {
          document.querySelectorAll('.chat-content')[0].style.height = '93%';
          var textareaMsg = document.getElementById("textareaMsg");
          textareaMsg.style.marginLeft = "0px";
          this.personInfoSpan = [14, 0, 10];
          const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
          if (isMobile) {
            document.querySelectorAll('.chatInputs')[0].style.margin = '0%';
          } else {
            document.querySelectorAll('.chatInputs')[0].style.margin = '3%';
          }
        });
      } else {
        this.$nextTick(() => {
          document.querySelectorAll('.chat-content')[0].style.height = '88%';
          this.personInfoSpan = [1, 17, 6];
        });
      }
    },
    //赋值对话列表
    assignmentMesList(msgList) {
      this.chatList = msgList
    },
    //获取对话列表
    getMesList() {
      return this.chatList
    },
    //清除当前对话列表
    clearMsgList() {
      this.chatList = []
    },
    //发送信息
    sendMsg(msgList) {
      this.chatList.push(msgList);
      this.scrollToBottom();
    },
    //点击头像查看是否开通vip
    async showPayDialog() {
      const checkvVipSuccess = await this.checkIsVip();
      let userId = this.userId;
      if (userId == null) {
        this.$notify({
          title: '请先绑定绑定会员账号！',
          duration: 2000,
          type: "error",
          position: 'top-right'
        });
        return false;
      }
      if (checkvVipSuccess) {
        this.$notify({
          title: '您已是chatPro会员!',
          duration: 2000,
          type: "success",
          position: 'top-right'
        });
      } else {
        console.error('您还未开通VIP');
        this.errorDialogVisible = true;
        this.Pay(userId);
        // 定义定时器 每隔5秒查询下支付状态
        this.timer = setInterval(() => {
          this.queryPaymentStatus();
          console.log("定时器")
        }, 5000);
      }
    },
    //判断是否开通会员
    async checkIsVip() {
      let userId = this.userId;
      if (userId == null) {
        this.$notify({
          title: '请先绑定会员账号！',
          duration: 2000,
          type: "error",
          position: 'top-right'
        });
        return false;
      }
      console.log("userId" + this.userId);
      let data = {
        'userId': userId
      };
      try {
        const response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_SERVER + '/api/user/isVip',
          data: data
        });
        if (response.data.success) {
          // 遍历 options 数组，将所有模型的 locked 属性设置为 false
          this.options.forEach(option => {
            option.locked = false;
          });
        }
        return response.data.success; // 这里假设 res.data.success 是一个布尔值
      } catch (error) {
        console.error("VIP 检查请求失败：", error);
        // 这里可以添加错误处理逻辑，例如通知用户请求失败
        return false;
      }
    },

    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    // 点击取消支付按钮的事件处理
    handleErrorDialogCancel() {
      // 可根据需要自定义逻辑
      this.errorDialogVisible = false;
      this.stopTimer(); // 停止定时器
      this.selectedModel = 'gpt-3.5-turbo';
    },
    // 关闭弹窗时重置错误消息
    handleErrorDialogClose() {
      this.errorMessage = '';
      this.errorDialogVisible = false;
      this.stopTimer(); // 停止定时器
    },
    // 点击确认成功
    async handleErrorDialogConfirm() {
      const isPaySuccess = await this.queryPaymentStatus();
      if (isPaySuccess) {
        this.$notify({
          title: '恭喜您支付成功！',
          duration: 2000,
          type: 'success',
          position: 'top-right'
        });
      } else {
        this.$notify({
          title: '支付失败，请重新发起支付！',
          duration: 2000,
          type: 'error',
          position: 'top-right'
        });
      }
    },

    async queryPaymentStatus() {
      if (!this.payNumber || !this.userId) {
        this.$notify({
          title: '参数异常!',
          duration: 2000,
          type: 'warning',
          position: 'top-right'
        });
        return false; // 直接返回false表示参数不合法
      }
      let data = {
        'payNumber': this.payNumber,
        'userId': this.userId
      };
      try {
        const response = await axios({
          method: 'POST',
          url: process.env.VUE_APP_SERVER + '/api/pay/alipay/alipaycallback',
          data: data
        });
        if (response.data.message === 'success') {
          this.errorDialogVisible = false;
          this.stopTimer(); // 停止定时器
          return true; // 支付成功
        } else {
          return false; // 支付失败
        }
      } catch (error) {
        console.error('支付查询出现错误', error);
        return false; // 发生错误，支付失败
      }
    },

    sendText() {
      this.rows = 1;
      this.$nextTick(() => {
        this.acqStatus = false
      })
      const dateNow = FormatDate();

      if (this.inputMsg || this.uploadedFileUrl) {
        let chatMsg = {
          headImg: USER_HEAD_IMG_URL,
          name: USER_NAME,
          time: dateNow,
          msg: this.inputMsg,
          chatType: 0, //信息类型，0文字，1图片
          uid: "user", //uid
          session: '',
          uploadedFileUrl: this.uploadedFileUrl || null // 添加上传的文件 URL
        };
        if (this.chatList.length === 0) {
          chatMsg.session = this.generateUUID()
        }
        this.sendMsg(chatMsg);
        let chatBeforeResMsg = {
          headImg: AI_HEAD_IMG_URL,
          name: this.friendInfo.name,
          time: FormatDate(getNowTime()),
          msg: "",
          chatType: 0, //信息类型，0文字，1图片
          uid: this.friendInfo.id, //uid
        };
        this.sendMsg(chatBeforeResMsg)
        const xhr = new XMLHttpRequest();
        let chatModel = this.selectedModel;
        console.log('发送的模型:', this.selectedModel);
        let content = encodeURIComponent(this.inputMsg);
        if (this.uploadedFileUrl) {
          content = `${this.uploadedFileUrl} ${this.inputMsg}`;
        }
        let url = process.env.VUE_APP_SERVER + `/api/chat/streamChat?content=${content}&chatModel=${encodeURIComponent(chatModel)}`;
        console.log('最终的URL:', url);
        const userId = this.userId;
        if (userId) {
          url += `&userId=${encodeURIComponent(userId)}`;
        }
        const session = this.chatList[0].session;
        url += `&session=${encodeURIComponent(session)}`;
        xhr.open('GET', url);
        xhr.setRequestHeader('Content-Type', 'text/event-stream');
        // 监听 readyStateChange 事件
        xhr.onreadystatechange = () => {
          const currentResLocation = this.chatList.length - 1;
          // 如果 readyState 是 3，表示正在接收数据
          if (xhr.readyState === 3) {
            // 将数据添加到文本框中
            this.chatList[currentResLocation].msg = xhr.responseText;
            this.scrollToBottomIfAtBottom();
          }
          // 如果 readyState 是 4，表示完成请求
          if (xhr.readyState === 4) {
            this.acqStatus = true;
            if (xhr.status === 200) {
              let responseData;
              if (xhr.response.includes("errorCode")) {
                responseData = JSON.parse(xhr.response);
                if (responseData.errorCode) {
                  // 后端抛出异常， 如果errorCode 等于50001 则表示额度用完
                  if (responseData.errorCode == '50001') {
                    this.chatList[currentResLocation].msg = responseData.errorMessage;
                    this.errorDialogVisible = true;
                    this.Pay(userId);
                  } else {
                    this.chatList[currentResLocation].msg = responseData.errorMessage;
                  }
                }
              } else {
                // 请求正常，接收到完整的数据
                this.chatList[currentResLocation].msg = xhr.responseText;
                if (this.chatList.length === 2) {
                  let sessionList = this.$parent.sessionList;
                  sessionList.forEach(session => {
                    session.id = session.id + 1;
                  });
                  const obj = {
                    id: 1,
                    title: this.chatList[0].msg,
                    dataList: this.chatList,
                    session: this.chatList[0].session
                  };
                  this.$parent.sessionList.unshift(obj);
                  this.$parent.clickSession(obj);
                }
              }
            } else {
              this.chatList[currentResLocation].msg = "请求出错，请重试！";
            }
          }
        };
        // 发送请求
        xhr.send();
        this.inputMsg = "";
        this.uploadedFileUrl = null;
        // this.$parent.updateMoneyInfo();
      } else {
        this.$nextTick(() => {
          this.acqStatus = true
        });
        this.$message.warning(this.$t('message.msg_empty'))
      }
    },

    //调用支付宝扫码付
    Pay(userId) {
      this.totalAmount = 10;
      if (userId == null) {
        this.$notify({
          title: '请先绑定绑定会员账号！',
          duration: 2000,
          type: "error",
          position: 'bottom-right'
        });
        return false;
      }
      let data = {
        'userId': userId,
        'totalAmount': this.totalAmount,
        'payType': '1'
      }
      axios({
        method: 'POST',
        url: process.env.VUE_APP_SERVER + '/api/pay/alipay/qr_code',
        data: data,
      }).then(res => {
        this.payNumber = res.data.content.payNumber; // 将返回的payNumber赋值给模型层的payNumber
        console.log("当前订单id" + this.payNumber);
        QRCode.toCanvas(this.$refs.qrcodeCanvas, res.data.content.qrCode, (error) => {
          if (error) {
            console.error(error);
          } else {
            console.log('QRCode generated.');
          }
        });
      })
    },

    // 触发文件上传
    triggerFileUpload() {
      // 通过 ref 引用触发隐藏的文件上传 input
      this.$refs.fileInput.click();
    },

    clearUploadedFile() {
      this.uploadedFileUrl = null;
      this.uploadedFileName = "";
      this.$refs.fileInput.value = ""; // 重置文件输入的值
    },

    isImage(url) {
      return /\.(jpg|jpeg|png|gif|bmp)$/i.test(url);
    },
    getFileName(url) {
      // 假设文件名是 URL 的最后一部分
      return url.split('/').pop();
    },

    // 处理文件上传
    // 处理文件选择
    async handleFileChange(event) {
      const file = event.target.files[0]; // 获取用户选择的文件

      if (!file) {
        return; // 如果没有选择文件，直接返回
      }
      // 创建 FormData 对象
      const formData = new FormData();
      formData.append("file", file);
      formData.append("module", "chatPro");

      try {
        // 发起文件上传请求
        const response = await axios.post(
            `${process.env.VUE_APP_SERVER}/api/cos/uploadCosPublicFile`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data", // 设置请求头
              },
            }
        );
        // 上传成功后处理响应
        if (response.status === 200 && response.data) {
          console.log("上传成功：", response.data);

          // 假设后端返回的文件 URL 在 response.data.url 中
          this.uploadedFileUrl = response.data.data.url || ""; // 保存文件 URL
          this.uploadedFileName = file.name; // 保存文件名
        } else {
          throw new Error("上传失败，服务器返回异常响应");
        }
      } catch (error) {
        console.error("文件上传失败：", error);
        alert("文件上传失败，请重试！");
      }
    },


    generateUUID() {
      const array = new Uint8Array(16);
      crypto.getRandomValues(array);
      let uuid = '';

      for (let i = 0; i < array.length; i++) {
        uuid += array[i].toString(16).padStart(2, '0');
        if (i === 3 || i === 5 || i === 7 || i === 9) {
          uuid += '-';
        }
      }
      return uuid;
    },
    //监听滚动事件，发生滚动时，即滚动条滚动或滚动内容改变时,分别获取到滚动条的位置、视口高度和内容高度
    onScroll() {
      const scrollDom = this.$refs.chatContent;
      const scrollTop = scrollDom.scrollTop;
      const offsetHeight = scrollDom.clientHeight;
      const scrollHeight = scrollDom.scrollHeight;
      // 当滚动到底部，设置 isAutoScroll 为 true
      this.isAutoScroll = scrollTop + offsetHeight === scrollHeight;
    },
    //获取窗口高度并滚动至最底层
    scrollBottom() {
      this.$nextTick(() => {
        // if (!this.isAutoScroll) return; // 如果 isAutoScroll 为 false，不执行滚动方法
        const scrollDom = this.$refs.chatContent;
        animation(scrollDom, scrollDom.scrollHeight - scrollDom.offsetHeight);
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const scrollDom = this.$refs.chatContent;
        if (scrollDom)
          scrollDom.scrollTop = scrollDom.scrollHeight
      })
    },
    scrollToTop() {
      this.$nextTick(() => {
        const scrollDom = this.$refs.chatContent;
        if (scrollDom)
          scrollDom.scrollTop = 0
      })
    },
    //这个方法的作用是在滚动条滚动到底部时，保持滚动条在底部，确保用户可以始终看到最新的聊天内容
    scrollToBottomIfAtBottom() {
      this.$nextTick(() => {
        const scrollDom = this.$refs.chatContent;
        if (scrollDom) {
          const threshold = 30 // 阈值，表示滚动条到底部的距离阈值
          const distanceToBottom = scrollDom.scrollHeight - scrollDom.scrollTop - scrollDom.clientHeight
          if (distanceToBottom <= threshold) {
            scrollDom.scrollTop = scrollDom.scrollHeight;
            setTimeout(() => {
              scrollDom.scrollTop = scrollDom.scrollHeight;
            }, 0);
          }
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.image-input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* 确保容器占满可用空间 */
  max-width: 1200px; /* 可根据需要设置最大宽度 */
}

textarea.inputs {
  width: 100%;
  box-sizing: border-box;
  padding-left: 70px; /* 为左侧文件展示区域预留空间 */
  padding-right: 40px; /* 为右侧发送按钮预留空间 */
  resize: none;
}

.uploaded-file-container img {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.uploaded-file-container span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40px;
  display: inline-block;
}

.image-wrapper {
  position: absolute;
  top: 10px;
  left: 43px;
  z-index: 1;
}

.pasted-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.remove-image-button {
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
  margin-left: -10px;
  margin-top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
}


.iconfont:hover {
  color: rgb(29, 144, 245);

  .block {
    opacity: 1;
  }
}

::v-deep {
  .el-textarea__inner {
    background-color: rgb(66, 70, 86);
    border-radius: 15px;
    border: 2px solid rgb(34, 135, 225);
    /* padding: 10px; */
    box-sizing: border-box;
    transition: 0.2s;
    font-size: 20px;
    color: #fff;
    font-weight: 100;
    /* margin: 0 20px; */
    width: 98%;
    height: 70px !important;

  }
}

pre {
  background-color: #211f1f !important;
  border-radius: 20px !important;
  box-shadow: 0px 0px 9px 0px #000000 !important;
  color: #ffff !important;
}

.hljs {
  background-color: #211f1f !important;
  border-radius: 20px !important;
  box-shadow: 0px 0px 9px 0px #000000 !important;
  color: #ffff !important;
}

textarea::-webkit-scrollbar {
  width: 3px;
  /* 设置滚动条宽度 */
}

textarea::-webkit-scrollbar-thumb {
  background-color: rgb(66, 70, 86);
  /* 设置滚动条滑块的背景色 */
  border-radius: 50%;
  /* 设置滑块的圆角 */
}

.spinner {
  width: 50px;
  height: 50px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.chat-window {
  width: 100%;
  height: 100%;
  margin-left: 0px;
  position: relative;

  .top {
    &::after {
      content: "";
      display: block;
      clear: both;
    }

    .head-pic {
      float: left;
    }

    .info-detail {
      float: left;
      margin: 5px 20px 0;

      .name {
        font-size: 23px;
        font-weight: 600;
        color: #fff;
      }

      .detail {
        color: black;
        font-size: 12px;
        margin-top: 2px;
      }
    }

    .el-icon-lock {
      color: #F56C6C; /* This color is just an example, you can change it as needed */
    }

    .other-fun {
      float: right;
      margin-top: 20px;

      span {
        margin-left: 30px;
        cursor: pointer;
      }

      // .icon-tupian {

      // }
      input {
        display: none;
      }
    }
  }


  .textarea {
    &:focus {
      outline: none;
    }
  }

  .botoom {
    width: 100%;
    height: 85vh;
    background-size: 100% 100%;
    border-radius: 20px;
    background-color: #f2f2f2;
    padding: 0;
    box-sizing: border-box;
    position: relative;

    .chat-content {
      width: 100%;
      height: 85%;
      overflow-y: scroll;
      padding: 20px;
      background-color: #f2f2f2;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 3px;
        /* 设置滚动条宽度 */
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgb(66, 70, 86);
        /* 设置滚动条滑块的背景色 */
        border-radius: 50%;
        /* 设置滑块的圆角 */
      }

      .chat-friend {
        width: 100%;
        float: left;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;

        .chat-text {
          float: left;
          max-width: 90%;
          padding: 15px;
          max-width: 650px;
          border-radius: 20px 20px 20px 5px;
          background-color: #fff;
          text-align: left;
        }

        .chat-img {
          img {
            max-width: 300px;
            max-height: 200px;
            border-radius: 10px;
          }
        }

        .info-time {
          margin: 10px 0;
          color: #131313;
          font-size: 14px;
          display: flex;
          justify-content: flex-start;

          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 10px;
          }

          span {
            line-height: 30px;
            color: black;
          }

          span:last-child {
            color: rgb(101, 104, 115);
            margin-left: 10px;
            vertical-align: middle;
          }
        }
      }

      .chat-me {
        width: 100%;
        float: right;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        .chat-text {
          float: right;
          max-width: 90%;
          padding: 15px;
          border-radius: 20px 20px 5px 20px;
          background-color: #95ec69;
          color: #131313;
          word-break: break-all;
          text-align: left;
        }

        .chat-img {
          img {
            max-width: 300px;
            max-height: 200px;
            border-radius: 10px;
          }
        }

        .info-time {
          margin: 10px 0;
          color: black;
          font-size: 14px;
          display: flex;
          justify-content: flex-end;

          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            vertical-align: middle;
            margin-left: 10px;
          }

          span {
            line-height: 30px;
          }

          span:first-child {
            background-color: #f2f2f2;
            margin-right: 10px;
            vertical-align: middle;
          }
        }
      }
    }

    .chat-file img, .chat-file span {
      border-radius: 8px;
      display: block;
      width: 182px;
      height: 200px;
      margin-top: 2px;
    }

    .chatInputs {
      width: 100%;
      position: absolute;
      bottom: 0;
      margin: 3%;
      display: flex;
      background-color: #f2f2f2;

      .boxinput {
        width: 50px;
        height: 50px;
        background-color: #f2f2f2;
        border-radius: 15px;
        border: 1px solid rgb(80, 85, 103);
        box-shadow: 0px 0px 5px 0px rgb(0, 136, 255);
        position: relative;
        cursor: pointer;

        img {
          width: 30px;
          height: 30px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .emoji {
        transition: 0.3s;
        width: 50px;
        min-width: 50px;
      }

      .luyin {
        color: #fff;
        margin-left: 1.5%;
        font-size: 30px;
        text-align: center;
        transition: 0.3s;
        width: 50px;
        min-width: 50px;
      }

      .inputs {
        width: 95%;
        height: 50px;
        background-color: rgb(255, 255, 255);
        border-radius: 15px;
        border: 2px solid rgb(34, 135, 225);
        padding: 10px;
        box-sizing: border-box;
        transition: 0.2s;
        font-size: 20px;
        color: #fff;
        font-weight: 100;
        margin: 0 20px;

        &:focus {
          outline: none;
        }
      }

      .send {
        background-color: rgb(42, 166, 218);
        border: 0;
        transition: 0.3s;
        box-shadow: 0px 0px 5px 0px rgba(0, 136, 255);

        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(0, 136, 255);
        }
      }
    }
  }
}


.line {
  position: relative;
  width: 94%;
  margin-left: 2%;
  height: 2px;
  background: linear-gradient(to right, red, yellow, green);
  animation: shrink-and-expand 2s ease-in-out infinite;

}

.line::before,
.line::after {
  content: "";
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background: inherit;
}

.line::before {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  left: 0;
  transform-origin: left;
  animation: shrink-left 2s ease-in-out infinite;
}

.line::after {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  right: 0;
  transform-origin: right;
  animation: shrink-right 2s ease-in-out infinite;
}

@keyframes shrink-and-expand {

  0%,
  100% {
    transform: scaleX(1);
  }

  50% {
    transform: scaleX(0);
  }
}

@keyframes shrink-left {

  0%,
  50% {
    transform: scaleX(1);
  }

  50.1%,
  100% {
    transform: scaleX(0);
  }
}

@keyframes shrink-right {

  0%,
  50% {
    transform: scaleX(1);
  }

  50.1%,
  100% {
    transform: scaleX(0);
  }
}

@media only screen and (min-width: 768px) { // 当屏幕宽度大于或等于768px时
  .chat-window {
    margin-left: 20px;

    .botoom {
      padding: 20px;
    }
  }
  .chat-window {
    .botoom {
      .chatInputs {
        width: 90%;
      }
    }
  }
  .text-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-container {
    display: flex;
    justify-content: center;
  }


  .dialog-content {
    line-height: 1.8;
    font-size: 14px;
    color: #333;
    font-family: 'Microsoft YaHei', sans-serif;
    padding: 20px;
  }

  .dialog-content p {
    margin-bottom: 15px;
  }

  .dialog-content h4 {
    margin: 20px 0 10px;
    font-size: 16px;
    color: #0d41a9;
    font-weight: bold;
    font-family: 'Microsoft YaHei', sans-serif;
  }

  .chatpro-title {
    font-size: 20px;
    font-family: 'Microsoft YaHei', sans-serif;
    font-weight: bold;
    color: #0d41a9;
  }

  .highlight {
    margin-top: 20px;
    text-align: center;
  }

  .gradient-text {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Microsoft YaHei', sans-serif;
    color: #0d41a9;
  }

  .el-dialog__title {
    font-size: 25px;
    font-family: 'Microsoft YaHei', sans-serif;
    color: #0d41a9;
    font-weight: bold;
  }
  .highlight-price {
    font-weight: bold;
    color: #3b648d; /* 选择一个醒目的颜色 */
    font-family: 'Microsoft YaHei', sans-serif;
  }

}
</style>
