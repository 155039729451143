<template>
  <div class="form-container">
    <el-card style="width: 450px">
      <div slot="header" class="clearfix">
        <span>{{ isLogin ? '登录' : '注册' }}</span>
      </div>
      <el-form v-if="isLogin" :model="loginForm" ref="loginForm" label-width="80px" class="form">
        <el-form-item label="用户名" class="form-item">
          <el-input v-model="loginForm.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" class="form-item">
          <el-input type="password" v-model="loginForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item class="form-item">
          <el-button type="primary" @click="handleLogin" class="form-button">登录</el-button>
        </el-form-item>
        <el-form-item class="form-item">
          <el-button type="text" @click="toggleForm" class="toggle-button">去注册</el-button>
        </el-form-item>
      </el-form>

      <el-form v-else :model="registerForm" ref="registerForm" label-width="80px" class="form">
        <el-form-item label="手机号" class="form-item">
          <el-input v-model="registerForm.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="验证码" class="form-item">
          <el-input v-model="registerForm.verifyCode" autocomplete="off">
            <template slot="append">
              <el-button @click="sendVerificationCode" :disabled="isSendingCode">
                {{ isSendingCode ? `${countdown}s` : '发送验证码' }}
              </el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="密码" class="form-item">
          <el-input type="password" v-model="registerForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item class="form-item">
          <el-button type="primary" @click="handleRegister" class="form-button">注册</el-button>
        </el-form-item>
        <el-form-item class="form-item">
          <el-button type="text" @click="toggleForm" class="toggle-button">去登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>

import axios from '@/util/request';
import JSEncrypt from 'jsencrypt';
import { PUBLIC_KEY } from '@/util/publicKey';

export default {
  name: "login",
  data() {
    return {
      isLogin: true,
      loginForm: {
        phone: '',
        password: ''
      },
      registerForm: {
        phone: '',
        verifyCode: '',
        password: ''
      },
      isSendingCode: false,
      countdown: 60,
      userInfoToken:""
    };
  },

  methods: {
    toggleForm() {
      this.isLogin = !this.isLogin;
    },
    handleLogin() {
      console.log('Login details:', this.loginForm);
      if(!this.loginForm.phone || !this.loginForm.password){
        this.$message.error('账号密码不能为空');
        return;
      }
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(PUBLIC_KEY);
      const encryptedPasswords = encryptor.encrypt(this.loginForm.password);
      console.log("加密后密码"+ encryptedPasswords)
      if (!encryptedPasswords) {
        this.$message.error('密码加密失败');
        return;
      }
      const data ={
        phone:this.loginForm.phone,
        email:this.loginForm.phone,
        password:encryptedPasswords
      };
      axios({
        method:'POST',
        url:  process.env.VUE_APP_SERVER + '/api/user/login',
        data:data
      }).then(response =>{
          if(response.data.code=="0"){
            let userPhone= response.data.data.phone;
            let userIds= response.data.data.userId;
            this.userInfoToken= response.data.data.accessToken;
            const userData = {
              userPhone: userPhone,
              userIdKey: userIds
            };
            // 将对象转换为JSON字符串
            const userDataJSON = JSON.stringify(userData);
            localStorage.setItem("userData",userDataJSON);
            localStorage.setItem('userInfoToken', this.userInfoToken);
            const expirationTime = Date.now() + 3600 * 1000;//一小时
            //const expirationTime = Date.now() + 60 * 1000; // 1分钟
            localStorage.setItem('expirationTime', expirationTime.toString()); // 将数值转换为字符串存储
            this.$message.success('恭喜你登录成功');
            this.$router.push({ name: 'ChatHome' });
          }else {
            this.$message.error(response.data.msg);
          }
      })
    },

    handleRegister() {
      if (
          !this.registerForm.password ||
          !this.registerForm.phone ||
          !this.registerForm.verifyCode
      ) {
        this.$message.error('请输入所有必填项');
        return;
      }
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(PUBLIC_KEY);
      const encryptedPassword = encryptor.encrypt(this.registerForm.password);
      console.log("加密后密码"+ encryptedPassword)
      if (!encryptedPassword) {
        this.$message.error('密码加密失败');
        return;
      }
      debugger
      const data = {
        phone: this.registerForm.phone,
        verifyCode: this.registerForm.verifyCode,
        password: encryptedPassword,
      };
      axios({
        method: 'POST',
        url:  process.env.VUE_APP_SERVER + '/api/user/addUser',
        data: data,
      }).then(response => {
         let result = response.data;
          if(result.msg=='success'){
            this.$message.success('恭喜你注册成功');
            this.toggleForm(); // 注册成功后切换到登录表单
          }else {
            this.$message.error('注册失败');
          }
        });
    },

    async  sendVerificationCode() {
      if (this.isSendingCode) return;
      // 验证手机号格式是否正确（示例中假设为11位数字）
      const phoneRegex = /^[0-9]{11}$/;
      if (!phoneRegex.test(this.registerForm.phone)) {
        this.$message.error('请输入有效的手机号');
        return;
      }
      const isRegistered = await this.checkPhoneRegister();
      if (isRegistered) {
        // 如果手机号未注册，则停止后续逻辑
        this.$message.error('手机号已注册');
        return;
      }
      this.isSendingCode = true;
      this.countdown = 60;
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(timer);
          this.isSendingCode = false;
        }
      }, 1000);

      // 发送验证码逻辑，使用GET请求的params传递参数
      axios({
        method: 'GET',
        url:  process.env.VUE_APP_SERVER + '/api/user/sendSmsCode',
        params: { phone: this.registerForm.phone }
      }).then(response => {
            this.$message.success('验证码发送成功');
          })
          .catch(error => {
            clearInterval(timer);
            this.isSendingCode = false;
            this.countdown = 0;
            this.$message.error('验证码发送失败');
          });
    },

    async checkPhoneRegister() {
      if (!this.registerForm.phone) {
        this.$message.error('手机号不能为空');
        return false;
      }
      try {
        const response = await axios({
          method: 'GET',
          url: process.env.VUE_APP_SERVER + '/api/user/checkPhoneRegister',
          params: { phone: this.registerForm.phone }
        });
        return response.data.data;//验证当前手机号是否注册
      } catch (error) {
        this.$message.error('检查手机号注册状态时发生错误');
        return false;
      }
    }
  }

}
</script>

<style scoped>
.form-container {
  width: 400px;
  margin: 100px auto;
  background-color: #f2f2f2;
}
.form {
  width: 100%;
}
.form-item {
  margin-left: -20px; /* 左移20px，根据需要调整 */
}
.form-button {
  width: 100%;
  text-align: center;
}
.toggle-button {
  width: 100%;
  text-align: center;
  display: block;
  margin-top: 10px;
}

</style>